<template>
  <div>502</div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Exp502",
  computed: {
    ...mapState("setting", ["pageMinHeight"]),
    minHeight() {
      return this.pageMinHeight ? this.pageMinHeight + "px" : "100vh";
    },
  },
};
</script>

<style scoped lang="less"></style>
